import * as ActionTypes from '../actions/types/PersonalizationActionTypes';

const PersonalizationReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVED_AVAILABLE_SKU_PERSONALIZATION:
      return {
        ...state,
        skuId: action.data.response.sku_id,
        productName: action.data.productName,
        productBrandName: action.data.productBrandName,
        availableSkuPersonalizations: action.data.response.available_personalizations,
      };
    case ActionTypes.RECEIVED_SAVE_PERASONALIZATION_FORM_DATA:
      return {
        ...state,
        customizedInputsData: action.payload,
        savedPersonalization: state.lastGeneratedPersonalization,
        lastGeneratedPersonalization: null,
      };
    case ActionTypes.RECEIVED_RESET_PERASONALIZATION_FORM_DATA:
      return {
        customizedInputsData: null,
        savedPersonalization: null,
        lastGeneratedPersonalization: null,
        availableSkuPersonalizations: null,
      };

    case ActionTypes.RECEIVED_UPDATE_SKU_PERSONALIZATION:
      return {
        ...state,
        lastGeneratedPersonalization: action.payload,
      };
    default:
      return state;
  }
};

export default PersonalizationReducer;
