import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';
// @ts-expect-error no types
import { createTracker } from 'redux-segment';
import LogRocket from 'logrocket';
import reducers from '../reducers';

// Create Segment Tracker
const tracker = createTracker();

/* eslint no-underscore-dangle: ["error", { "allow": ["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] }] */
const composeEnhancers =
  typeof window !== 'undefined' &&
  (window.zola.env === 'development' || window.zola.env === 'staging')
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const enhancers = composeEnhancers(applyMiddleware(thunk, tracker, LogRocket.reduxMiddleware()));

const finalReducers = combineReducers({
  ...reducers,
});

const store = createStore(finalReducers, enhancers);

export type RootState = ReturnType<typeof store.getState>;

export default store;
