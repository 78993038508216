import React from 'react';
import { withRouter, BrowserRouter } from 'react-router-dom';

// @ts-expect-error no types yet
import { TrackPageView } from '@zola/zola-ui/src/components/util/TrackPageView';

import ModalRoot from './components/common/ModalRoot';
import Notifications from './components/common/notifications/Notifications';
import ToastsContainer from './components/common/ToastsContainer';
import ScrollToTop from './components/common/ScrollToTop';

const TrackPageViewWithRouter = withRouter(TrackPageView);

interface ClientRouterProps {
  children: React.ReactNode;
}

/**
 * This component wraps client-side routes to provide tracking and containers for modals, notifications & toasts
 */
const ClientRouter = (props: ClientRouterProps): JSX.Element => {
  const { children } = props;
  return (
    <BrowserRouter>
      <ScrollToTop>
        <TrackPageViewWithRouter />
        {children}
        <ModalRoot />
        <Notifications />
        <ToastsContainer />
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default ClientRouter;
