import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FocusTrap from 'focus-trap-react';
import { VideoModal } from '@zola/zola-ui/src/components/Modal/VideoModal';

import Loadable from 'react-loadable';
import Loader from './ui/Loader/Loader';
import { hideModal } from '../../actions/ModalActions';

const CropModal = Loadable({
  loader: () => import('./modal/CropModal'),
  loading: Loader,
});

const FinishFlowModal = Loadable({
  loader: () => import('./modal/FinishFlowModal'),
  loading: Loader,
});

const ZolaReviewModal = Loadable({
  loader: () => import('./modal/ZolaReviewModal'),
  loading: Loader,
});

const ProductDetailModal = Loadable({
  loader: () => import('./modal/ProductDetailModal'),
  loading: Loader,
});

const PersonalizationModal = Loadable({
  loader: () => import('./modal/PersonalizationModal'),
  loading: Loader,
});
const WalkthroughModal = Loadable({
  loader: () => import('./modal/WalkthroughModal'),
  loading: Loader,
});

const MODAL_COMPONENTS = {
  PRODUCT: ProductDetailModal,
  CROP_MODAL: CropModal,
  VIDEO_MODAL: VideoModal,
  REVIEW_MODAL: ZolaReviewModal,
  FINISH_EXTERNAL_FLOW: FinishFlowModal,
  PERSONALIZATION_MODAL: PersonalizationModal,
  WALKTHROUGH_MODAL: WalkthroughModal,
  /* other modals */
};

class ModalRoot extends Component {
  static getDerivedStateFromProps(props, state) {
    if (!props.modalType && state.previousActiveElement) {
      return { previousActiveElement: null };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      previousActiveElement: null,
    };

    this.onBackgroundClick = this.onBackgroundClick.bind(this);
    this.onEscape = this.onEscape.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleExplicitClose = this.handleExplicitClose.bind(this);
  }

  componentDidMount() {
    const { previousActiveElement } = this.state;
    if (!previousActiveElement) {
      this.setState({
        previousActiveElement: document.activeElement,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEscape);
  }

  onShow() {
    if (document && document.body) {
      const orig = document.body.className;
      document.body.className = `${orig} modal-open`.trim();
    }
    document.addEventListener('keydown', this.onEscape);
  }

  onBackgroundClick(e) {
    const { modalOptions } = this.props;
    if (modalOptions?.explicitClose) {
      return;
    }
    if (e.target === e.currentTarget) this.handleClose();
  }

  onEscape({ keyCode }) {
    const { modalOptions } = this.props;
    if (modalOptions?.explicitClose) {
      return;
    }
    if (keyCode === 27) this.handleClose();
  }

  handleClose() {
    const { hideModalFn } = this.props;
    if (document && document.body) {
      document.body.className = document.body.className.replace(/ ?modal-open/g, '');
    }
    document.removeEventListener('keydown', this.onEscape);

    const { previousActiveElement } = this.state;
    if (previousActiveElement) {
      previousActiveElement.focus();
      this.setState({ previousActiveElement: null });
    }
    hideModalFn();
  }

  handleExplicitClose() {
    const { modalOptions } = this.props;
    if (modalOptions?.onExplicitClose) {
      modalOptions.onExplicitClose();
    }
    this.handleClose();
  }

  render() {
    const { modalType, modalProps, modalOptions } = this.props;
    if (!modalType) {
      return null;
    }
    this.onShow();
    const SpecificModal = MODAL_COMPONENTS[modalType];

    return (
      <>
        <FocusTrap
          focusTrapOptions={{
            clickOutsideDeactivates: true,
          }}
        >
          <div
            id="modal"
            role="dialog"
            aria-modal="true"
            aria-label={modalOptions?.ariaLabel || 'Zola Modal'}
            className="modal in"
            style={{ zIndex: 1050, display: 'block' }}
            onClick={this.onBackgroundClick}
          >
            <div className={`modal-dialog modal-${modalOptions?.size || 'lg'}`}>
              {/* tabIndex on a div tag will show a warning. I believe it is a false alarm. */}
              {/* tab to navigate still works. */}
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
              <div className="modal-content" tabIndex="0">
                {!modalProps.hideClose && (
                  <button
                    aria-label="Close"
                    type="button"
                    className="modal-close"
                    onClick={this.handleExplicitClose}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                )}
                <SpecificModal {...modalProps} hideModalFn={this.handleClose} />
              </div>
            </div>
          </div>
        </FocusTrap>
        <div className="modal-backdrop in" />
      </>
    );
  }
}

ModalRoot.propTypes = {
  modalType: PropTypes.string,
  modalProps: PropTypes.shape({
    hideClose: PropTypes.bool,
    callback: PropTypes.func,
  }),
  hideModalFn: PropTypes.func,
  modalOptions: PropTypes.shape({
    size: PropTypes.string,
    ariaLabel: PropTypes.string,
    explicitClose: PropTypes.bool,
    onExplicitClose: PropTypes.func,
  }),
};

const mapStateToProps = (state) => state.modal;

const mapDispatchToProps = {
  hideModalFn: hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot);
