export const REQUEST_REGISTRY_INFO_BY_SLUG = 'zola/registry/REQUEST_REGISTRY_INFO_BY_SLUG';
export const RECEIVED_REGISTRY_INFO_BY_SLUG = 'zola/registry/RECEIVED_REGISTRY_INFO_BY_SLUG';
export const REQUEST_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID =
  'zola/registry/REQUEST_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID';
export const RECEIVED_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID =
  'zola/registry/RECEIVED_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID';
export const REQUEST_REGISTRY_INFO_BY_COLLECTION_ITEM_ID =
  'zola/registry/REQUEST_REGISTRY_INFO_BY_COLLECTION_ITEM_ID';
export const RECEIVED_REGISTRY_INFO_BY_COLLECTION_ITEM_ID =
  'zola/registry/RECEIVED_REGISTRY_INFO_BY_COLLECTION_ITEM_ID';
export const REQUEST_COLLECTION_INFO_BY_REGISTRY_ID =
  'zola/registry/REQUEST_COLLECTION_INFO_BY_REGISTRY_ID';
export const RECEIVED_COLLECTION_INFO_BY_REGISTRY_ID =
  'zola/registry/RECEIVED_COLLECTION_INFO_BY_REGISTRY_ID';
