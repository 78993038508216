import * as ActionTypes from '../actions/types/OverviewActionTypes';

const initialState = {
  nextStepsMessages: [],
  messagesAndPlannerLoading: false,
  products: [],
  additionalProductsLoading: false,
  productsLoading: true,
};

const overviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.HYDRATE_OVERVIEW_MESSAGES: {
      return { ...state, nextStepsMessages: action.data };
    }
    case ActionTypes.OVERVIEW_PLANNER_STATS: {
      return { ...state, plannerStats: action.data };
    }
    case ActionTypes.MESSAGES_AND_PLANNER_IS_LOADING: {
      return { ...state, messagesAndPlannerLoading: !state.messagesAndPlannerLoading };
    }
    case ActionTypes.REQUEST_PRODUCTS: {
      return { ...state, productsLoading: true };
    }
    case ActionTypes.REQUEST_ADDITIONAL_PRODUCTS: {
      return { ...state, additionalProductsLoading: true };
    }
    case ActionTypes.RECEIVE_PRODUCTS: {
      return { ...state, productsLoading: false, products: action.data };
    }
    case ActionTypes.RECEIVE_ADDITIONAL_PRODUCTS: {
      return { ...state, additionalProductsLoading: false, products: action.data };
    }
    case ActionTypes.HYDRATE_PLANNER_STATS: {
      return { ...state, plannerStats: { ...state.plannerStats, ...action.data } };
    }
    case ActionTypes.SET_USING_DEFAULT_PARAMETERS_FALSE: {
      return {
        ...state,
        plannerStats: { ...state.plannerStats, using_default_parameters: action.data },
      };
    }
    default:
      return state;
  }
};

export default overviewReducer;
