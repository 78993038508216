/* eslint-disable camelcase */
/**
 * Created by zoechodosh on 2/17/17.
 */
import type { WClientUserAccountView } from '@zola/svc-web-api-ts-client';
import { Reducer } from 'redux';
import * as ActionTypes from '../actions/types/UserActionTypes';

export interface UserContext extends WClientUserAccountView {
  is_spoof: boolean;
  has_registry: boolean;
  has_wedding_account: boolean;
  has_website: boolean;
  has_guest_list: boolean;
  has_inspiration_account: boolean;
  has_invitation_account: boolean;
  has_marketplace_account: boolean;
  has_store_account: boolean;
  has_completed_onboarding: boolean;
  is_inspiration_vendor: boolean;
  is_inspiration_admin: boolean;
  has_board_items: boolean;
  is_guest: boolean;
}
export interface UserState {
  busy: boolean;
  userContext: UserContext | null;
  hasIdentified: boolean;
  hasGuestIdentified: boolean;
}

const initialState: UserState = {
  busy: false,
  userContext: null,
  hasIdentified: false,
  hasGuestIdentified: false,
};

const userReducer: Reducer<UserState, any> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_USER: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_USER: {
      return Object.assign({}, state, { busy: false, userContext: action.payload });
    }
    case ActionTypes.IDENTIFY_USER: {
      return Object.assign({}, state, { hasIdentified: true });
    }
    case ActionTypes.IDENTIFY_GUEST_USER: {
      return Object.assign({}, state, { hasGuestIdentified: true });
    }
    default:
      return state;
  }
};

export default userReducer;
