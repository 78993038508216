import * as ActionTypes from '../actions/types/ThirdPartyTypes';

const initialState = {
  redirect: false,
  isSubmitting: false,
  bulkImport: {
    currentIndex: 0,
    registryUrlError: false,
    selectedRetailerError: false,
  },
};

const thirdPartyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REDIRECT: {
      return { ...state, redirect: true };
    }
    case ActionTypes.TOGGLE_SUBMITTING: {
      return { ...state, isSubmitting: !state.isSubmitting };
    }
    case ActionTypes.RECEIVED_SUPPORTED_RETAILERS: {
      return { ...state, bulkImport: { ...state.bulkImport, supportedRetailers: action.payload } };
    }
    case ActionTypes.INCREMENT_INDEX: {
      return {
        ...state,
        bulkImport: { ...state.bulkImport, currentIndex: state.bulkImport.currentIndex + 1 },
      };
    }
    case ActionTypes.SET_SELECTED_RETAILER: {
      return {
        ...state,
        bulkImport: {
          ...state.bulkImport,
          registryUrlError: false,
          selectedRetailerDetails: action.payload,
        },
      };
    }
    case ActionTypes.THROW_REGISTRY_URL_ERROR: {
      return { ...state, bulkImport: { ...state.bulkImport, registryUrlError: true } };
    }
    case ActionTypes.THROW_SELECTED_RETAILER_ERROR: {
      return { ...state, bulkImport: { ...state.bulkImport, selectedRetailerError: true } };
    }
    case ActionTypes.RESET_RETAILER: {
      return {
        ...state,
        bulkImport: { ...state.bulkImport, selectedRetailerError: false, currentIndex: 1 },
      };
    }
    case ActionTypes.RESET_SELECTED_RETAILER_ERROR: {
      return { ...state, bulkImport: { ...state.bulkImport, selectedRetailerError: false } };
    }
    default:
      return state;
  }
};

export default thirdPartyReducer;
