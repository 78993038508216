export const REQUEST_SIZE = 'zola/cart/REQUEST_SIZE';
export const RECEIVE_SIZE = 'zola/cart/RECEIVE_SIZE';
export const REQUEST_CART = 'zola/cart/REQUEST_CART';
export const RECEIVE_CART = 'zola/cart/RECEIVE_CART';
export const REQUEST_CREDIT_CARDS = 'zola/cart/REQUEST_CREDIT_CARDS';
export const RECEIVE_CREDIT_CARDS = 'zola/cart/RECEIVE_CREDIT_CARDS';
export const REQUEST_REMOVE_CREDIT_CARD = 'zola/cart/REQUEST_REMOVE_CREDIT_CARD';
export const RECEIVE_REMOVE_CREDIT_CARD = 'zola/cart/RECEIVE_REMOVE_CREDIT_CARD';
export const REQUEST_APPLY_CREDITS = 'zola/cart/REQUEST_APPLY_CREDITS';
export const RECEIVE_APPLY_CREDITS = 'zola/cart/RECEIVE_APPLY_CREDITS';
export const REQUEST_SUBMIT_CHECKOUT = 'zola/cart/REQUEST_SUBMIT_CHECKOUT';
export const RECEIVE_SUBMIT_CHECKOUT = 'zola/cart/RECEIVE_SUBMIT_CHECKOUT';
export const ADD_ITEM = 'zola/cart/ADD_ITEM';
export const ADDED_ITEM = 'zola/cart/ADDED_ITEM';
export const ADD_ITEM_TO_CART = 'zola/cart/ADD_ITEM_TO_CART';
export const ADDED_ITEM_TO_CART = 'zola/cart/ADDED_ITEM_TO_CART';
export const DELETE_ITEM = 'zola/cart/DELETE_ITEM';
export const DELETED_ITEM = 'zola/cart/DELETE_ITEM';
export const UPDATE_ITEM_QUANTITY = 'zola/cart/UPDATE_ITEM_QUANTITY';
export const RESET_CART = 'zola/cart/RESET_CART';
export const UNLOAD_CART = 'zola/cart/UNLOAD_CART';
